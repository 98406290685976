.header {
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #517e86;
  padding: 1rem;
  z-index: 1000;
}

.header .logo_div .logo {
  color: black;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.header .logo_div .logo span {
  color: black;
}

.header .logo_div .logo .icon {
  margin-top: -8px;
}

.toggle_navbar .icon {
  color: var(--white-color);
  font-size: 25px;
}

.toggle_navbar .icon:hover {
  color: var(--primary-color);
}

.sticky_header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #232836;
  box-shadow: 3px 3px 8px -3px #ddd;
}

.sticky_header_color {
  color: #fff;
}

.logo > h1 > img {
  width: 60px !important;
  border-radius: 50%;
  height: auto;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .nav_bar {
    background-color: var(--grey-color);
    position: absolute;
    height: auto;
    width: 100%;
    padding: 1rem;
    top: 0;
    left: -500%;
    box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
    transition: 0.5s ease;
    z-index: 1000;
  }

  .nav_bar .nav_lists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
  }

  .nav_bar .nav_lists .nav_item {
    padding: 0.7rem 0;
  }

  .nav_bar .nav_lists .nav_item .nav_link {
    color: var(--text-color);
    font-size: 0.9rem;
    font-weight: 600;
  }

  .nav_bar .nav_lists .nav_item .nav_link:hover {
    color: var(--primary-color);
  }

  .header_btn {
    flex-direction: column;
  }

  .header_btn .btn {
    margin-top: 1rem;
    font-weight: 600;
  }

  .header_btn .btn:hover {
    color: var(--white-color);
  }

  .login_btn {
    background: none;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }

  .login_btn:hover {
    background-color: var(--hover-color);
    color: var(--white-color);
  }

  .close_navbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: var(--primary-color);
  }

  .close_navbar:hover .icon {
    color: var(--hover-color);
  }

  .active_navbar {
    left: 0;
  }
}

@media screen and (min-width: 769px) {
  .toggle_navbar,
  .close_navbar {
    display: none;
  }

  .header {
    padding: 1.5rem 2rem;
  }

  .nav_bar .nav_lists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .nav_item .nav_link {
    color: var(--white-color);
    padding: 0 0.45rem;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .nav_item .nav_link:hover {
    color: var(--primary-color);
  }

  .header_btn .btn {
    margin-left: 1rem;
  }

  .header_btn .login_btn {
    background: none;
    border: 1px solid var(--primary-color);
  }

  .header_btn .login_btn:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
}

@media screen and (min-width: 960px) {
  .nav_item {
    padding: 0 0.7rem;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    padding: 1rem 8.5rem;
  }
}

.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: -100%;
  color: #fff;
  background: var(--primary-color);
  z-index: 1;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 46px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 999;
}

.go-top .icon {
  position: relative;
  top: 5px;
}

.go-top.active {
  right: 0;
}

.go-top:hover {
  color: #fff;
  background-color: var(--hover-color);
}
