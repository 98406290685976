.services .main_container {
    gap: 1rem;
}

.services .services_post {
    border-radius: 1rem;
    overflow: hidden;
    gap: 12px;
    padding: 0.5rem;
    box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.189);
}

.services .services_post .img_div {
    position: relative;
    height: 200px;
    width: 100%;
    border: 5px solid var(--grey-color);
    border-radius: 1rem;
    box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.468);
    overflow: hidden;
}

.services .services_post .img_div:hover {
    transform: scale(1.1);
    transition: 3s ease;
}

.services .services_post .img_div img {
    height: 100%;
    object-fit: cover;
}

.services .services_post .services_details h3 {
    color: var(--black-color);
    font-weight: 600;
    padding-bottom: 10px;
}

.services .services_post .services_details p {
    font-size: 13px;
    color: var(--text-color);
    font-weight: 500;
}

.services .services_post .services_details .link {
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 600;
    padding-top: 0.5rem;
    width: max-content;
}

.services .services_post .services_details .link:hover {
    color: var(--hover-color);
}

@media screen and (min-width: 884px) {
    .services .main_container {
        grid-template-columns: repeat(2, 1fr);
    }
}