.home {
    height: 78vh;
    width: 100%;
    position: relative;
    background-image: linear-gradient(#517e86c4, #949fdf1a), url(../assets/images/main.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.sec_container .home_text {
    text-align: center;
}

.sec_container .home_text .title {
    color: var(--white-color);
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
}

.sec_container .home_text .sub_title {
    color: var(--white-color);
    font-size: 13px;
    font-weight: 300;
    opacity: 0.9;
    padding: 1rem 0;
    max-width: 70%;
    text-align: center;
    margin: auto;
}

.sec_container .home_text .btn {
    padding: 0.6rem 1.5rem;
}

@media screen and (max-width: 360px) {
    .home .sec_container .home_text .title {
        font-size: 1.2rem;
    }

    .home .sec_container .home_text .sub_title {
        font-size: 12px;
    }
}

@media screen and (min-width: 556px) {
    .home .sec_container .home_text .title {
        font-size: 2.5rem;
    }

    .home .sec_container .home_text .sub_title {
        font-size: 18px;
    }
}

@media screen and (min-width: 720px) {
    .home .sec_container .home_text {
        padding: 1rem 10rem;
    }

    .home .sec_container .home_text .title {
        font-size: 3.5rem;
        margin: auto;
        line-height: 4rem;
    }

    .home .sec_container .home_text .sub_title {
        width: 70%;
    }
}

@media screen and (min-width: 1024px) {
    .home .sec_container .home_text .sub_title {
        font-size: 25px;
    }
}