.footer {
    background-color: var(--grey-color);
    padding: 2rem 0;
}

.footer .sec_container {
    gap: 1.5rem;
    text-align: center;
}

.footer .sec_container .footer_logo .logo {
    color: var(--black-color);
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.footer .sec_container .footer_logo .logo .icon {
    font-size: 25px;
    color: var(--primary-color);
    margin-left: 0.5rem;
}

.footer .socials {
    gap: 0.5rem;
    margin: auto;
    justify-content: center;
}

.footer .socials .icon {
    font-size: 30px;
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 8px;
    color: var(--white-color);
}

.footer .socials .icon:hover {
    background: var(--black-color);
}

.footer .footer_links {
    display: grid;
    row-gap: 0.2rem;
}

.footer .footer_links .link_title {
    color: var(--black-color);
    padding-bottom: 0.5rem;
    font-weight: 700;
}

.footer_links li {
    display: inline-block;
}

.footer_links li:hover {
    transform: translateX(10px);
    color: var(--primary-color);
}

.footer_links li,
.phone,
.email {
    color: var(--text-color);
    font-size: 13px;
    font-weight: 500;
}

.footer_para {
    color: var(--black-color);
    text-align: center;
    margin-top: 0.5rem;
    font-size: 13px;
}

@media screen and (min-width: 448px) {
    .footer .sec_container {
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        /* text-align: start !important; */
        align-items: flex-start;
    }

    .footer .footer_links {
        justify-content: center;
    }
}

@media screen and (min-width: 640px) {
    .footer .sec_container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 900px) {
    .footer .sec_container {
        padding: 2rem;
    }
}