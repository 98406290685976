.rooms_col {
    flex-basis: 31%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: left;
}

.rooms_col img {
    width: 100%;
    transition: 0.5s;
    border-radius: 10px;
}

.rooms_col img:hover {
    box-shadow: 0 0 20px 10px rgba(54, 58, 121, 0.2);
}

.rooms_col h3 {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 15px;
}