@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    background-color: var(--white-color);
    font-family: var(--poppins-family);
}

/* Colors and font family */
:root {
    --primary-color: #09c7f6;
    --hover-color: #0ba5cb;
    --white-color: #ffffff;
    --text-color: #79797c;
    --white-color-deam: #ededed;
    --black-color: #2e2e2e;
    --grey-color: #f5f5f5;
    --grey-text: #bebebe;
    --input-color: #f8f6f7;
    --poppins-family: 'Poppins', sans-serif;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.section {
    padding: 4rem 0 2rem;
}

.container {
    padding: 0 1.5rem;
}

.icon {
    font-size: 2rem;
    cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    align-items: center;
}

.btn {
    padding: 0.4rem 1.5rem;
    background: var(--primary-color);
    border-radius: 3rem;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--white-color);
    font-weight: 500;
}


.btn:hover {
    color: var(--white-color);
}

.sec_intro {
    max-width: 70%;
    text-align: center;
    margin: 0 auto 2rem;
}

.sec_intro .sec_title {
    color: var(--primary-color);
    line-height: 20px;
    padding-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
}

.sec_intro p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    padding: 10px;
}

img {
    width: 100%;
    height: auto;
}

input {
    font-size: 100%;
}

.Sub_header {
    height: 30vh;
    width: 100%;
    background-image: linear-gradient(#517e86c4, #949fdf1a), url(https://image3.jdomni.in/banner/16082022/B1/66/B9/8DE1C253C226A7A9FC59C7786E_1660644672252.jpg?output-format=webp);
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #fff;
}

.Sub_header h1 {
    transform: translateY(90px);
    font-size: 2rem;
    color: var(--white-color);
}

.row {
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
}

.PageBlock {
    position: relative;
    margin: 0px !important;
    padding: 0px;
}

.verticalLine {
    width: 2px;
    height: 50px;
    background: var(--primary-color);
    margin: 20px auto 10px;
}

.Clear {
    clear: both;
}

@media screen and (min-width: 556px) {
    .services_post {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .Sub_header {
        height: 20vh;
    }
}

@media screen and (min-width: 1240px) {
    .container {
        width: 95% !important;
        margin: auto !important;
    }

    .section {
        padding: 5rem 0 3rem;
    }
}

@media screen and (min-width: 2560px) {
    body {
        zoom: 1.7;
    }
}

::selection {
    background-color: var(--primary-color);
    color: var(--white-color);
}

::-webkit-scrollbar {
    width: 10px;
    background-color: var(--grey-color);
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
}