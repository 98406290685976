.main_content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 15px;
}

.main_content .box {
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
    box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    height: 250px;
    overflow: hidden;
}

.main_content .box img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}