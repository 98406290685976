.contact_us {
    width: 80%;
    margin: auto; 
}

.contact_us .row {
    align-items: center;
}

.content_col {
    flex-basis: 48%;
    margin-bottom: 30px;
}

.content_col div {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.content_col div .icon {
    font-size: 28px;
    color: var(--primary-color);
    margin: 10px;
    margin-right: 30px;
}

.content_col div p {
    padding: 0;
}

.content_col div h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
}

.content_col .btn {
    position: relative;
    font-size: 1rem;
    height: 50px;
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.content_col input,
.content_col textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    border: 1px solid #CACACA;
    border-radius: 6px;
    outline: none;
}

.content_col input:focus,
.content_col textarea:focus {
    border: 1px solid var(--primary-color);
    border-bottom-width: 2px;
}