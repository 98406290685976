.about_us {
    width: 100%;
    margin: 0 auto;
}

.about_us .row {
    align-items: center;
}

.about-col {
    flex-basis: 45%;
    text-align: center;
    padding: 0 2px;
}

.about-col img {
    width: 100%;
    border-radius: 15px;
}

.about-col h1 {
    padding-top: 0;
}

.about-col p {
    padding: 15px 0 25px;
}

@media screen and (max-width: 768px) {}